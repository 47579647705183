import { For, Match, Show, Switch } from "solid-js";
import type { TeamsCardProps, membersProps } from "../_model";
import { solidstate } from ":shared/utils/state-manager";
import { send_request } from "../apis";
import { PrimaryButton } from ":src/components/form/buttons";
import { createStore } from "solid-js/store";
import { global } from ":global";
import placeholder_img from "../assets/images/person_placeholder.png";

export default function TeamsCard(props: TeamsCardProps) {
  // const [loading, setLoading] = createSignal(false);
  const $loading = solidstate.create<boolean>(false);
  const $request_sent = solidstate.create<boolean>(false);
  const requester_email = global.api.auth.jwt.email_address;
  const [formData, setFormData] = createStore({
    requester_email: requester_email,
    receiver_email: "",
    status: "pending",
  });

  const SendRequest = async (e: Event, receiver_email: string) => {
    $loading.set(true);
    e.preventDefault();
    console.log("leader receiver_email:: ", receiver_email);

    try {
      setFormData("receiver_email", receiver_email);
      console.log("formData:: ", JSON.stringify(formData));
      await send_request(JSON.stringify(formData));
      // success
      $loading.set(false);
      $request_sent.set(true);
      global.store.actions.alert.pushToast({
        type: "success",
        message: "request Sent",
      });
    } catch (error: any) {
      $loading.set(false);
    }
  };

  return (
    <div class="relative w-full h-auto max-w-[26rem]  flex flex-col  rounded-xl bg-white px-3 py-3 border-1 border-solid border-gray-300">
      <div class="flex-1">
        <div class="w-full flex justify-between items-center gap-3 relative pb-3 ">
          <div class="flex justify-center items-center gap-1">
            <div
              class={`w-9px h-9px rounded-full ${
                props?.status.toLowerCase() === "completed" ? "bg#green" : "bg#brown"
              }`}
            />
            <p
              class={`text-[12px] py-3 md:py-0 text-start ${
                props?.status.toLowerCase() === "completed" ? "text#green" : "text#brown"
              }`}
            >
              {props?.status ?? "No status"}
            </p>
          </div>
          <p class="text#warn bg#bg-orange text-12px font-600  absolute right-0 px-2 py-[3px] rounded-md">
            {props?.track ?? "No track"}
          </p>
        </div>
        <h3 class="text-black font-bold text-[16px] py-1 md:py-0">{props?.team_name ?? "No team name"}</h3>
      </div>

      <div class="flex justify-between items-center py-1 relative gap-4 pt-2">
        <div class=" flex flex-row justify-start items-center">
          <For each={props?.members}>
            {(member, index) => {
              const left_shift = 3;
              console.log("left_shift:: ", left_shift);
              return (
                <Show
                  fallback={
                    <img
                      width={44}
                      height={44}
                      class={`-ml-${index() === 0 ? 0 : left_shift} w-44px h-44px rounded-full object-cover `}
                      src={placeholder_img}
                      alt={`img${index}`}
                      loading="lazy"
                    />
                  }
                  when={member.image}
                >
                  <img
                    width={44}
                    height={44}
                    class={`-ml-${index() === 0 ? 0 : left_shift} w-44px h-44px rounded-full object-cover `}
                    src={member?.image}
                    alt={`img${index}`}
                  />
                </Show>
              );
            }}
          </For>
        </div>

        <Switch>
          <Match when={$loading.value}>
            <PrimaryButton label="Loading..." classes="px-6! py-[12px]! !text-[16px]" disabled />
          </Match>

          {/* <Match when={props?.members.length<1}>
            <PrimaryButton label="Team is Empty" classes="px-6! py-[12px]! !text-[16px]" disabled/>
          </Match> */}

          <Match when={props.status === "Completed"}>
            {/* <button class={`bg#disabled cursor-not-allowed text-white px-4 py-[12px] rounded-md font-bold text-[14px] flex justify-center items-center gap-2  `}>
          Team is full
          </button>  */}
            <PrimaryButton classes="!px-4 !py-[12px] !text-[16px]" label="Team is full" disabled />
          </Match>

          <Match when={props.status === "In-Complete" || props.status === "Active"}>
            <PrimaryButton
              classes="px-2! py-[12px]! !text-[14px]"
              onClick={(e) =>
                SendRequest(e, props?.members?.find((member: membersProps) => member?.role === "leader")?.email)
              }
              label="Send Request"
            />
          </Match>
        </Switch>
      </div>
    </div>
  );
}
